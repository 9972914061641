import { SIDE_PANEL_ANCHOR_CLASS } from './constants';

export function initSidePanelAnchors() {
  window.addEventListener('click', (e) => {
    const target = e.target as HTMLElement;
    if (target.classList.contains(SIDE_PANEL_ANCHOR_CLASS)) {
      e.preventDefault();
      const href = target.getAttribute('href');

      if (!href) return;

      window.open(href, undefined, 'popup');
    }
  });
}

import { useEffect } from 'react';

let cardPopoverMeasureElement: HTMLElement | null = null;

function computeScrollbarSizes(el: HTMLElement) {
  const w = el.offsetWidth - el.clientWidth;
  const h = el.offsetHeight - el.clientHeight;
  document.documentElement.style.setProperty('--g--root-scrollbar-width', `${w}px`);
  document.documentElement.style.setProperty('--g--root-scrollbar-height', `${h}px`);
}

function computeCardPopoverImageStartScale() {
  if (!cardPopoverMeasureElement) {
    cardPopoverMeasureElement = document.createElement('div');
    const { style } = cardPopoverMeasureElement;
    style.position = 'absolute';
    style.left = '-100%';
    style.top = '-100%';
    style.pointerEvents = 'none';
    style.visibility = 'hidden';
    style.width = 'var(--g--card-width)';
    style.aspectRatio = '16 / 9';
    document.body.appendChild(cardPopoverMeasureElement);
  }

  const { width } = cardPopoverMeasureElement.getBoundingClientRect();
  document.documentElement.style.setProperty('--g--card-width-unitless', `${width}`);
}

export function useDynamicCssVariables() {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const el = document.getElementById('__next');
    if (!el) return;

    const computeVariables = () => {
      computeScrollbarSizes(el);
      computeCardPopoverImageStartScale();
    };

    computeVariables();

    window.addEventListener('resize', computeVariables);
    return () => {
      window.removeEventListener('resize', computeVariables);
      document.documentElement.style.removeProperty('--g--root-scrollbar-width');
      document.documentElement.style.removeProperty('--g--root-scrollbar-height');
      document.documentElement.style.removeProperty('--g--card-popup-image-start-scale');
    };
  }, []);
}

import { NormalizedCacheObject } from '@apollo/client';
import { useMemo } from 'react';
import { initApollo } from '../utils/apollo-client';

export function useApollo(pageProps: { __APOLLO_CACHE__: NormalizedCacheObject }) {
  const state = pageProps.__APOLLO_CACHE__;
  const store = useMemo(() => initApollo(state), [state]);

  return store;
}
